'use strict';
import { throttle } from 'underscore';

jQuery(document).ready(function($) {
	setInterval(function(){
		   $.get('/keepSessionAlive');
	}, 25*60*1000); // mins * 60 * 1000

    $('html').scrollLeft(0); // reset horizontal scroll

    // var setPageHeight = function() {
    //     document.getElementsByClassName('page')[0].style.height = window.innerHeight + 'px';
    // };

    // setPageHeight();

    let cacheWindowInnerSize = function() {
        window._innerHeight = window.innerHeight;
        window._innerWidth = window.innerWidth;
    };

    cacheWindowInnerSize();

    let initDebug = function() {
        window._debug = {
            selector: false,
            websocket: false,
        }
    };

    initDebug();

    var resized = function(e) {
        cacheWindowInnerSize();
        App.vent.trigger(App.vent['view:resized'], e);
        // setPageHeight();
    };

    $.ajaxSetup({ cache: false }); // Hack for IE to prevent a jQuery Ajax request from caching

    /**
     * Если ресайз тригается не на окне, то и нам не надо его тут обрабатывать
     */
    $(window).on('resize', throttle(function(e) {
        if (e.target == window) {
            resized(e);
        }
    }, 30));

    $(document).keydown(function(e) {
        if (e.keyCode == 27) {
            App.vent.trigger(App.vent['keydown:escape'], e);
        } else if (e.keyCode == 46) {
            App.vent.trigger(App.vent['keydown:delete'], e);
        }
    });

    $(document).ajaxError(function(event, jqxhr, settings, exception) {
    	if(settings.allowError){
    		// TODO something
    	} else{
    		App.vent.trigger(App.vent['ajax:error'], jqxhr, settings);
    	}
    });


    var afterPrint = function() {
        App.vent.trigger(App.vent['view:afterprint']);
    };
    if (window.matchMedia) {
        var mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(function(mql) {
            if (!mql.matches) {
                afterPrint();
            }
        });
    }
    window.onafterprint = afterPrint;

    // check users from Saratov
        if (!localStorage.getItem('saratov')) {
            var userFromSaratov = false;

            if (App.controller.acceptLanguage.toLowerCase().indexOf('ru') > -1) {
                App.controller.trackEvent(Messages.getText('ga.category.user.ru'),
                                          Messages.getText('ga.info.user.ru'));
            }
            if (App.controller.country.toLowerCase() === 'ru') {
                App.controller.trackEvent(Messages.getText('ga.category.user.ru'),
                                          Messages.getText('ga.info.user.russia'));
            }
            if (App.controller.region.toLowerCase() === 'sar') {
                App.controller.trackEvent(Messages.getText('ga.category.user.ru'),
                                          Messages.getText('ga.info.user.saratov_region'));
            }
            if (App.controller.city.toLowerCase() === 'saratov') {
                App.controller.trackEvent(Messages.getText('ga.category.user.ru'),
                                          Messages.getText('ga.info.user.saratov'));
                userFromSaratov = true;
            }
            if (new Date().getTimezoneOffset() === -180) {
                App.controller.trackEvent(Messages.getText('ga.category.user.ru'),
                                          Messages.getText('ga.info.user.timezone.moscow'));
            }

            if (userFromSaratov) {
/*                var saratovText = '<p>Приглашаем Саратовских пользователей Kanbanchi на тусовку с разработчиками продукта. За чашкой чая мы расскажем вам об истории создания Kanbanchi и поможем стать более эффективными пользователями.</p>' +
                    '<p>Место проведения в районе Рабочей/Вольской (офис компании ЛАР, может измениться в зависимости от количества собравшихся). Будет викторина на знание продукта и всякие интересные подарки.</p>' +
                    '<p style="margin-bottom: 0;">Каждый участник получит ПРО версию Kanbanchi бесплатно. Запись в <a target="_blank" href="https://www.facebook.com/events/713377302122153/">facebook</a></p>';
                App.controller.showInfo(saratovText);
                */
            }

            localStorage.setItem('saratov', true);
        }

});
